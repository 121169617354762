import baseConfig from './config-base';

const newConfig = JSON.parse(JSON.stringify(baseConfig));
newConfig.user = 'camperparkamsterdam';

const extraIntroStep = {
    type: 'default',
    code: 'intro0',
    title: 'title-intro-0',
    buttons: [
        {
            label: 'btn-next',
            code: 'start-booking0',
        },
    ],
    layout: {
        rows: [
            {
                columns: [
                    {
                        type: 'title',
                        classes: ['col-12'],
                    },
                ],
            },
            {
                columns: [
                    {
                        type: 'text',
                        classes: ['col-12'],
                        data: 'intro-text-0',
                    },
                ],
            },
            {
                columns: [
                    {
                        type: 'buttons',
                        classes: ['col-12'],
                    },
                ],
            },
        ],
    },
};

newConfig.steps.splice(1, 0, extraIntroStep);
newConfig.steps.splice(2, 1);

export default newConfig;
